<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DE LOS CHECK-LIST PARA VERIFICAR IMPLEMENTACIÓN DE PROTOCOLOS EN SITIOS ARQUEOLÓGICOS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaCheckList.length}}</span>
                            <br>
                            <span class="text-muted">Verificaciones de protocolos en sitios arqueológicos</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de check-list de protocolos en sitios arqueológicos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarCheckList = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo check list</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Verificaciones de protocolos en sitios arqueológicos </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaCheckList" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarCheckList(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCheckList(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>

                                <template v-slot:cell(cantidadChecksSi)="param">
                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==2).length}}
                                </template>
                                <template v-slot:cell(cantidadChecksNo)="param">
                                    {{param.item.datosEvaluacion.filter(x => x.evaluacion==1).length}}
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarCheckList" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo check list</span>
            </h6>
            <CButtonClose @click="modalRegistrarCheckList = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCheckList)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Señaletica" active>
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="sitioArqueologico" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Sitio Arqueológico:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosCheckList.sitioArqueologico" :options="[{value:1,text:'LLAQTAPATA'},{value:2,text:'RUNKURACCAY'},{value:3,text:'SAYACMARCA'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Circuitos, corredores
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[0].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[0].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Puntos de acceso al destino
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[1].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[1].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Horario de apertura y cierre
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[2].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[2].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Distanciamiento físico
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[3].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[3].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Correcto lavado y/o desinfección de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[4].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[4].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Obligatoriedad de uso de mascarrilla
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[5].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[5].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Otros
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[6].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[6].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificación de lugares">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que espacios públicos, instalaciones y oficinas se encuentran limpias y desinfectadas
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[7].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[7].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificación de limpieza">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar la existencia de puntos para el lavado y/o de desinfección de manos, las cuales contiene.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[8].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[8].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que cuenta con puntos de lavado o desinfección de manos (alcohol en gel al 70%) en la zona de ingreso y otros con los insumos necesarios
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[9].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[9].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Información de lavado correcto de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[10].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[10].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Información de desinfección correcto de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[11].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[11].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Jabón líquido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[12].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[12].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Desinfectante de manos, alcohol al 70%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[13].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[13].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Papel toalla
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[14].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[14].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Baños públicos">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que los baños públicos se encuentren limpios e higienizados
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[15].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[15].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Limpieza y desinfección en tres turnos por día: antes del ingreso de visitantes, después del almuerzo y al finalizar la jornada.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[16].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[16].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Limpieza y desinfección con soluciones desinfectantes.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[17].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[17].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que los servicios higiénicos cuentan con Provisión continua de insumos básicos: papel, jabón y alcohol.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[18].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[18].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que en los servicios higiénicos se encuentra instrucciones par el lavado de manos y otros.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[19].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[19].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Residuos peligrosos">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar la correcta disposición de residuos peligrosos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[20].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[20].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que cuentan con tachos debidamente rotulados
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[21].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[21].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Limitar el aforo en los servicios higiénicos para respetar el distanciamiento de 1.5 mts
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[22].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[22].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que cuentan con tachos o recipientes rotulados con tapa vaivén o pedal
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[23].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[23].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que el personal de limpieza tiene conocimiento de la correcta disposición de RRSS peligrosos (EPPs) , las bolsas platicas deben ser amarradas y rociadas con desinfectante (hipoclorito de sodio y agua) para du disposición final y no se deben abrir nuevamente	
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[24].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[24].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Pasajero tienen conocimiento de la correcta disposición de RRSS peligrosos (EPPs), las bolsas plásticas( rojas) deben ser entregadas al personal encargado de RRSS, para su disposición final y no se deben mezclar	
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[25].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[25].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Personal de limpieza cuenta con EPPs necesarios: Mascarilla facial, guantes, careta facial	
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[26].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[26].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Cumple con">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Obligatoriedad de que los pasajeros y guías tengan puesta la mascarilla facial en todo el recorrido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[27].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[27].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Al ingreso control de temperatura
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[28].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[28].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Al ingreso es obligatorio el lavado y/o desinfección de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[29].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[29].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Al ingreso es obligatorio la desinfección de calzado
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[30].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[30].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Cumplimiento de distanciamiento físico en todo el recorrido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[31].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[31].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Ubicación de personal de vigilancia en puntos estratégicos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[32].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[32].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Esta prohibido el consumo de alimentos durante la visita
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[33].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[33].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Cuenta con materiales">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Termómetro infrarrojo
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[34].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[34].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Alcohol líquido o en gel al 70%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[35].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[35].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Espacio para la desinfección de visitantes
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[36].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[36].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Zona para el asilamiento de casos sospechosos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[37].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[37].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Marcadores de distancia u otro que cumpla dicho fin
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[38].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[38].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Desinfectante de calzados, pediluvio u otro
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[39].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[39].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar que cumple">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        No esta permitido la permanencia de guías de sitio al ingreso para evitar aglomeraciones y posible contagio
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[40].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[40].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        En caso de visitantes libres serán monitoreados por el personal del sitio arqueológico para el cumplimiento de medidas de bioseguridad (distanciamiento)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[41].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[41].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Esta permitido permanecer en espacios delimitados y/o señalizados
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[42].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[42].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Capacidad de admisión">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Aforo 50%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[43].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[43].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Temporalidad para la visita 2 horas por grupo o persona
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[44].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[44].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Grupo máximo de 8 personas, 7 pax y un guía
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[45].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[45].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Persona cuya temperatura es mayor a 38° C no será permitido su ingreso
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[46].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[46].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        No se admitirá a cualquier persona que se niegue a cumplir los protocolos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckList.datosEvaluacion[47].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckList.datosEvaluacion[47].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarCheckList = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarCheckList" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar check list</span></h6>
            <CButtonClose @click="modalActualizarCheckList = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarCheckList)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" variant="pills">
                            <b-tab title="Señaletica" active>
                                <b-row>
                                    <b-col md="6">
                                        <validation-provider name="sitioArqueologico" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Sitio Arqueológico:" class="mb-2">
                                                <b-form-select :state="getValidationState(validationContext)" v-model="datosCheckListActualizar.sitioArqueologico" :options="[{value:1,text:'LLAQTAPATA'},{value:2,text:'RUNKURACCAY'},{value:3,text:'SAYACMARCA'}]">
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>

                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Circuitos, corredores
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[0].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[0].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Puntos de acceso al destino
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[1].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[1].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Horario de apertura y cierre
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[2].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[2].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Distanciamiento físico
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[3].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[3].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Correcto lavado y/o desinfección de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[4].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[4].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Obligatoriedad de uso de mascarrilla
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[5].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[5].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Otros
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[6].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[6].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificación de lugares">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que espacios públicos, instalaciones y oficinas se encuentran limpias y desinfectadas
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[7].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[7].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificación de limpieza">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar la existencia de puntos para el lavado y/o de desinfección de manos, las cuales contiene.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[8].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[8].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que cuenta con puntos de lavado o desinfección de manos (alcohol en gel al 70%) en la zona de ingreso y otros con los insumos necesarios
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[9].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[9].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Información de lavado correcto de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[10].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[10].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Información de desinfección correcto de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[11].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[11].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Jabón líquido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[12].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[12].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Desinfectante de manos, alcohol al 70%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[13].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[13].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Papel toalla
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[14].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[14].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Baños públicos">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que los baños públicos se encuentren limpios e higienizados
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[15].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[15].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Limpieza y desinfección en tres turnos por día: antes del ingreso de visitantes, después del almuerzo y al finalizar la jornada.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[16].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[16].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Limpieza y desinfección con soluciones desinfectantes.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[17].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[17].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que los servicios higiénicos cuentan con Provisión continua de insumos básicos: papel, jabón y alcohol.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[18].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[18].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que en los servicios higiénicos se encuentra instrucciones par el lavado de manos y otros.
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[19].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[19].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Residuos peligrosos">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar la correcta disposición de residuos peligrosos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[20].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[20].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que cuentan con tachos debidamente rotulados
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[21].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[21].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Limitar el aforo en los servicios higiénicos para respetar el distanciamiento de 1.5 mts
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[22].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[22].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que cuentan con tachos o recipientes rotulados con tapa vaivén o pedal
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[23].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[23].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Verificar que el personal de limpieza tiene conocimiento de la correcta disposición de RRSS peligrosos (EPPs) , las bolsas platicas deben ser amarradas y rociadas con desinfectante (hipoclorito de sodio y agua) para du disposición final y no se deben abrir nuevamente	
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[24].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[24].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Pasajero tienen conocimiento de la correcta disposición de RRSS peligrosos (EPPs), las bolsas plásticas( rojas) deben ser entregadas al personal encargado de RRSS, para su disposición final y no se deben mezclar	
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[25].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[25].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Personal de limpieza cuenta con EPPs necesarios: Mascarilla facial, guantes, careta facial	
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[26].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[26].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Cumple con">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Obligatoriedad de que los pasajeros y guías tengan puesta la mascarilla facial en todo el recorrido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[27].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[27].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Al ingreso control de temperatura
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[28].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[28].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Al ingreso es obligatorio el lavado y/o desinfección de manos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[29].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[29].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Al ingreso es obligatorio la desinfección de calzado
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[30].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[30].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Cumplimiento de distanciamiento físico en todo el recorrido
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[31].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[31].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Ubicación de personal de vigilancia en puntos estratégicos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[32].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[32].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Esta prohibido el consumo de alimentos durante la visita
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[33].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[33].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Cuenta con materiales">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Termómetro infrarrojo
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[34].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[34].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Alcohol líquido o en gel al 70%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[35].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[35].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Espacio para la desinfección de visitantes
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[36].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[36].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Zona para el asilamiento de casos sospechosos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[37].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[37].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Marcadores de distancia u otro que cumpla dicho fin
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[38].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[38].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Desinfectante de calzados, pediluvio u otro
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[39].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[39].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Verificar que cumple">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        No esta permitido la permanencia de guías de sitio al ingreso para evitar aglomeraciones y posible contagio
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[40].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[40].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        En caso de visitantes libres serán monitoreados por el personal del sitio arqueológico para el cumplimiento de medidas de bioseguridad (distanciamiento)
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[41].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[41].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Esta permitido permanecer en espacios delimitados y/o señalizados
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[42].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[42].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Capacidad de admisión">
                                <b-row>
                                    <b-col md="12">
                                        <b-table-simple bordered show-empty small responsive outlined>

                                            <b-tbody>
                                                <b-tr>
                                                    <b-td width="55%" class="text-center font-weight-bold">
                                                        Puntos
                                                    </b-td>
                                                    <b-td width="15%" class="text-center font-weight-bold">
                                                        Evaluación
                                                    </b-td>
                                                    <b-td width="30%" class="text-center font-weight-bold">
                                                        Observaciones
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Aforo 50%
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[43].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[43].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Temporalidad para la visita 2 horas por grupo o persona
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[44].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[44].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Grupo máximo de 8 personas, 7 pax y un guía
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[45].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[45].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        Persona cuya temperatura es mayor a 38° C no será permitido su ingreso
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[46].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[46].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                                <b-tr>
                                                    <b-td>
                                                        No se admitirá a cualquier persona que se niegue a cumplir los protocolos
                                                    </b-td>
                                                    <b-td class="text-center">
                                                        <b-form-radio-group v-model="datosCheckListActualizar.datosEvaluacion[47].evaluacion" plain :options="comboSiNo"></b-form-radio-group>
                                                    </b-td>
                                                    <b-td>
                                                        <b-form-textarea v-model="datosCheckListActualizar.datosEvaluacion[47].observaciones" rows="2" max-rows="6" placeholder="Escriba aquí"></b-form-textarea>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarCheckList = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import firebase from 'firebase';
const dbCheckListPSA = firebase.firestore().collection('checkListPSA');

export default {
    data() {
        return {
            disabled: false,
            listaCheckList: [],
            camposLote: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "sitioArqueologico",
                    label: "Sitio Arqueológico",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksSi",
                    label: "Checks si cumple",
                    class: "text-center",
                },
                {
                    key: "cantidadChecksNo",
                    label: "Checks no cumple",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarCheckList: false,
            modalActualizarCheckList: false,
            comboSiNo: [{
                    value: 2,
                    text: 'Cumple'
                },
                {
                    value: 1,
                    text: 'No cumple'
                },
            ],
            datosCheckList: {
                idCheckList: '',
                idCliente: '',
                sitioArqueologico: '',
                datosEvaluacion: [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, ]
            },
            datosCheckListActualizar: {
                idCheckList: '',
                idCliente: '',
                sitioArqueologico: '',
                datosEvaluacion: [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, ]
            },
        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarCheckList() {
            console.log("entro 1");
            let me = this;
            dbCheckListPSA
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosCheckList.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaCheckList = [];
                    console.log(querySnapshot);
                    querySnapshot.forEach((doc) => {
                        me.listaCheckList.push({
                            idCheckList: doc.id,
                            idCliente: doc.data().idCliente,
                            sitioArqueologico: doc.data().sitioArqueologico,
                            datosEvaluacion: doc.data().datosEvaluacion,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                        console.log("entro 3");
                    });
                    me.listaCheckList.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                    me.filasTotales = me.listaCheckList.length;
                });
        },
        registrarCheckList() {
            let me = this;
            me.disabled = true;
            dbCheckListPSA.add({
                    idCliente: me.datosCheckList.idCliente,
                    sitioArqueologico: me.datosCheckList.sitioArqueologico,
                    datosEvaluacion: me.datosCheckList.datosEvaluacion,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarCheckList = false
                    me.disabled = false;
                    console.log("Entro registro");
                    me.listarCheckList();
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        actualizarCheckList() {
            let me = this;
            me.disabled = true;
            dbCheckListPSA.doc(me.datosCheckListActualizar.idCheckList).update({
                    idCliente: me.datosCheckListActualizar.idCliente,
                    sitioArqueologico: me.datosCheckListActualizar.sitioArqueologico,
                    datosEvaluacion: me.datosCheckListActualizar.datosEvaluacion,
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarCheckList = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarCheckList(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el check list?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbCheckListPSA.doc(param.item.idCheckList).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirModalActualizarCheckList(param) {
            this.datosCheckListActualizar.idCheckList = param.item.idCheckList;
            this.datosCheckListActualizar.sitioArqueologico = param.item.sitioArqueologico;
            this.datosCheckListActualizar.datosEvaluacion = param.item.datosEvaluacion;
            this.modalActualizarCheckList = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarCheckList() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosCheckList.sitioArqueologico = '';
            this.datosCheckList.datosEvaluacion = [{
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, {
                    evaluacion: '',
                    observaciones: ''
                }, ];

        },
        resetModalActualizarCheckList() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarCheckList: function (val) {
            if (val == false) {
                this.resetModalRegistrarCheckList();
            }
        },
        modalActualizarCheckList: function (val) {
            if (val == false) {
                this.resetModalActualizarCheckList();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCheckList.idCliente = this.datosCheckListActualizar.idCliente = user.idCliente;
            this.listarCheckList();
        }
    }

}
</script>